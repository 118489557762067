/* MusicPlayer.css */

.search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 20px;
}

.search-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.search-bar__input {
  width: 100%;
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  margin-right: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

@media (max-width: 600px) {
  .search-bar__input {
    font-size: 14px;
  }
}

.search-bar__input:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.search-bar__button {
  padding: 15px 30px;
  background-color: #18a047;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-bar__button:hover {
  background-color: #128536;
}

.search-bar__button:focus {
  outline: none;
}

.search-preview {
  width: 100%;
  padding: 1rem;
  border-radius: 4px;
  background-color: #222;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.5s ease;
}

@media (max-width: 600px) {
  .search-preview {
    width: 90%;
  }
}

.search-preview h4 {
  margin-bottom: 0.5rem;
  color: #fff;
}

.search-preview ul {
  list-style: none;
  padding: 0;
  animation: listChange 0.3s ease;
}

@keyframes listChange {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.search-preview li {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  color: #ccc;
  font-size: 16px;
  animation: fadeIn 0.5s ease;
}

.search-preview__item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #ccc;
  font-size: 16px;
  animation: fadeIn 0.5s ease;
  background-color: #222;
  padding: 0.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: background-color 0.2s, transform 0.3s ease;
  cursor: pointer;
}

.search-preview__item:hover {
  background-color: #333;
  transform: translateY(-4px);
}

.search-preview__song-image,
.search-preview__album-image,
.search-preview__artist-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 4px;
  margin-right: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.search-preview__song-image:hover,
.search-preview__album-image:hover,
.search-preview__artist-image:hover {
  transform: scale(1.05);
}

.search-preview__details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.search-preview__song-title,
.title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0.2rem;
  margin-block-start: 0em;
}


.search-preview__item-info,
.search-preview__artist-name {
  font-size: 10px;
  color: #ccc;
}

.search-preview__item-info--time {
  margin-left: 1rem;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
}

@media (max-width: 600px) {
  
  .search-preview__item-info,
  .search-preview__artist-name, .search-preview__song-title, .search-preview__song-title {
    font-size: 10px;
  }

  .search-preview__item-info--time, .search-preview__item-info--followers, .search-preview__item-info--date {
    font-size: 11px !important;
  }

  .search-preview__song-image,
  .search-preview__album-image,
  .search-preview__artist-image {
    width: 60px;
    height: 60px;
  }

  .search-bar__button{
    padding: 7.5px 15px;
  }
}

.search-preview__item-info--date,
.search-preview__item-info--followers {
  margin-left: 1rem;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
}
