.player-container {
  position: fixed;
  bottom: 0.5%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  border-radius: 20%;
  background-color: #333;
  padding: 2px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  transition: transform 0.3s ease-in-out; 
}

.player-container.bottom {
  transform: translateX(-50%) translateY(95%);
}

.player-container .player-content {
  max-width: 80%;
  margin: 0 auto;
  text-align: center; /* Center the player content horizontally */
}