.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-button {
  display: inline-block;
  background-color: #1db954;
  border: none;
  border-radius: 50px;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 320px;
  margin: 0 auto;
}

.login-button:hover {
  background-color: #18a047;
  transform: translateY(-2px);
}
