.dashboard {
  max-width: 1000px;
  width: 90%;
  margin: 0 auto;
  padding: 40px;
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}

.dashboard__title {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
}

.dashboard__profile-image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-bottom: 30px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dashboard__info {
  font-size: 18px;
  margin-bottom: 20px;
  color: #ccc;
}

.dashboard__section-title {
  font-size: 26px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
}

.dashboard__list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
}

.dashboard__item {
  position: relative;
  transition: transform 0.3s ease;
  background-color: #444;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 30px;
}

.dashboard__item:hover {
  transform: translateY(-5px);
}

.dashboard__album-image,
.dashboard__song-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 4px;
}

.dashboard__item-info {
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
}

.dashboard__audio {
  width: 100%;
}

.dashboard {
  border: 1px solid #333;
}

.dashboard__title,
.dashboard__section-title {
  color: #fff;
}

.dashboard__play-button {
  background-color: #1db954;
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dashboard__play-button:hover {
  background-color: #18a047;
}

.dashboard__album-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.dashboard__song-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #fff;
}

.dashboard__item-info {
  font-size: 12px;
  margin-bottom: 10px;
  color: #fff;
  opacity: 0.7;
}

.dashboard__item-info--bold {
  font-weight: bold;
  font-size: 16px;
  opacity: 1;
}

.dashboard__section-title {
  font-size: 26px;
  font-weight: bold;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #fff;
}
